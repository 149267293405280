import React from "react"
import { graphql } from "gatsby"

import Seo from "../components/SEO"
import Footer from "../components/footer"
import Wave from "../components/wave"

const Blogpage = ({ data, pageContext: { locale }, location }) => {
  const blogPage = data.allPrismicBlogpost.edges[0].node
  return (
    <>
      <div>
        <Seo
          title={blogPage.data.title.text + " | Blog de Josselin CAER"}
          desc={
            blogPage.data.seo_blog_description
              ? blogPage.data.seo_blog_description
              : ""
          }
          pathname={location.pathname}
          locale={locale}
        />
        <Wave
          title={blogPage.data.title.text}
          blog={true}
          last_publication_date={blogPage.last_publication_date}
        />

        <article className="relative max-w-3xl mx-auto font-serif z-10 pt-10 md:pt-0  ">
          {blogPage.data.body
            .filter(type => type.slice_type === "content")
            .map((content, i) => (
              <div
                key={i}
                className="richtext"
                dangerouslySetInnerHTML={{
                  __html: content.primary.richtext.html,
                }}
              />
            ))}
        </article>
      </div>
      <Footer />
    </>
  )
}

export default Blogpage

export const pageQuery = graphql`
  query($uid: String, $locale: String) {
    allPrismicBlogpost(filter: { uid: { eq: $uid } }) {
      edges {
        node {
          id
          last_publication_date
          uid
          data {
            seo_blog_description
            title {
              html
              text
            }
            body {
              ... on PrismicBlogpostDataBodyContent {
                id
                slice_type
                slice_label
                primary {
                  richtext {
                    text
                    html
                  }
                }
              }
            }
          }
        }
      }
    }

    allPrismicGlobal(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          lang
          data {
            back_to_home
          }
        }
      }
    }
  }
`
